import * as statusMapper from "@/service/error_request_mapper.js";

export class ExpenseReportContextsService {
  constructor(apis) {
    this.apis = apis;

    this.api = this.apis.getExpenseReportContextsApi();
  }

  async getAll() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async getById(contextId) {
    return this.api.getById(contextId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  async create(context) {
    return this.api.create(context).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(context.id);
      converter.add409AlreadyExists(context.label);
      converter.convert(error);
    });
  }

  async update(context) {
    return this.api.update(context).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(context.id);
      converter.convert(error);
    });
  }

  async delete(contextId) {
    return this.api.delete(contextId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(contextId);
      converter.convert(error);
    });
  }
}
